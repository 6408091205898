export class UserProfile{

  public static modelFromJson(obj:any){
    return new UserProfile(
      obj['userId'],
      obj['email'],
      obj['firstName'],
      obj['lastName'],
      obj['type'],
      obj['companyId'],
      obj['companyName'],
      obj['disabled'],
      obj['roles'],
    );
  }

  constructor(
    public userId: string ="",
    public email: string = "",
    public firstName: string = "",
    public lastName: string = "",
    public type: string = "",
    public companyId: string = "",
    public companyName: string = "",
    public disabled: boolean,
    public roles: string[]
  ){};

  get displayName(){
    return this.firstName + ' ' + this.lastName;
  }
  get initials(){
    let initials = '';
    if(this.firstName.length > 0)
    initials = this.firstName.substring(0,1);
    if(this.lastName.length > 0)
    initials = initials + this.lastName.substring(0,1);
    return initials;
  }

}
