import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IdentityService } from 'src/app/services/identity.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  public endRendering:boolean=false;
  public showButtonSpinner = false;
  public showErrorMessage: boolean = false;

  signInForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });


  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private idendityService: IdentityService
  ) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.endRendering = true;
    },2600);
  }

  onSubmit(){
    this.showButtonSpinner = true;
    this.idendityService.signIn(this.signInForm.value)
    .then((userCredential)=>{
      const user = userCredential.user;
      // console.log('userCredential:', userCredential);
      // console.log('user:', user);
      this.idendityService.setProfile();
      this.router.navigate(['/admin/dashboard']);
    })
    .catch((error) => {
      this.showErrorMessage = true;
      setTimeout(()=>{
        this.showErrorMessage = false;
      }, 2000);
      const errorCode = error.code;
      const errorMessage = error.message;
      // console.log('errorCode:', errorCode);
      // console.log('errorMessage:', errorMessage);
    })
    .finally(()=>{
      this.showButtonSpinner = false;
    });
  }

}
