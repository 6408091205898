import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { getAuth, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, updateProfile, createUserWithEmailAndPassword } from "firebase/auth";
import { User } from '../models/user.models';
import { UserProfile } from '../models/user-profile.model';
import { iUserSignIn } from '../interfaces/user-sign-in';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

    private currentUser: User = User.modelFromJson({});
    private currentUserProfile: UserProfile = UserProfile.modelFromJson({});

  constructor(
    private auth: Auth
  )
  {
    this.setProfile();

  }


  //----- Firestores Methods -----//

  public signIn(userSignIn: iUserSignIn){
    return signInWithEmailAndPassword(this.auth, userSignIn.email, userSignIn.password);
  }

  public signOut(){
    return signOut(this.auth);
  }

  public resetPassword(email: string){
    const auth = getAuth();
    return sendPasswordResetEmail(auth, email);
  }

  public setDisplayName(displayName: string){
    const auth = getAuth();
    if(auth.currentUser)
      return updateProfile(auth.currentUser, { displayName: displayName })
    else
      return new Promise((resolve,reject) => {
        // do some async task
        reject();
   });
  }

  //--------------------helpers -------------//

public hasDisplayName():boolean{
  const auth = getAuth();
  const user = auth.currentUser;
  if(user === null)
     return false
  if(user.displayName === null)
      return false;
  return true;
}

  public setProfile(){
    const auth = getAuth();
    const user = auth.currentUser;
    if(user){
      this.currentUser.displayName = user.displayName ? user.displayName : '';
      this.currentUser.email = user.email ? user.email : '';
      this.currentUser.userId = user.uid;
      this.splitDisplayName();

      this.currentUserProfile.userId = this.currentUser.userId;
      this.currentUserProfile.email = this.currentUser.email;
      //this.currentUserProfile.firstName = "Florencio";
      //this.currentUserProfile.lastName = "Lista";
      this.currentUserProfile.type = 'employee';
      this.currentUserProfile.companyId = "00000";
      this.currentUserProfile.companyName = "ENSIGN ARGENTINA S.A.";
      this.currentUserProfile.roles = ["admin"];


    }

  }

   public getUsername():string{
    return this.currentUser.email;
  }

  public getEmail():string{
    return this.currentUser.email;
  }

  public getDisplayName():string{
    return this.currentUser.displayName;
  }

  public getInitials():string{
    return this.currentUserProfile.initials;
  }

  public getCurrentUser(): User{
    return this.currentUser;
  }

  public getCurrentUserProfile(): UserProfile{
    return this.currentUserProfile;
  }

  private splitDisplayName(){
    if(this.currentUser.displayName.length > 0){
      const splittedName = this.currentUser.displayName.split(' ');
      if(splittedName[0].length > 0)
        this.currentUserProfile.firstName = splittedName[0];
      if(splittedName[1].length > 0)
        this.currentUserProfile.lastName = splittedName[1];
    }
    else if(this.currentUser.email.split('.').length > 0){
      const splittedName = this.currentUser.email.split('.');
      if(splittedName[0].length > 0)
        this.currentUserProfile.firstName = splittedName[0];
      if(splittedName[1].length > 0)
        this.currentUserProfile.lastName = splittedName[1];
    }
  }

}


