import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//--- Modules

//--- Components
import { SignInComponent } from './sign-in/sign-in.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    SignInComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  exports:[
    SignInComponent,
    ResetPasswordComponent
  ]
})
export class AuthModule { }
