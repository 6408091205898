import { NgModule } from '@angular/core';

//--- Modules
import { RouterModule, Routes } from '@angular/router';

//--- Guards
import { canActivate, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';


//--- Components
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { SignInComponent } from './pages/auth/sign-in/sign-in.component';


const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/sign-in']);
//const redirectLoggedInToSendEmail = () => redirectLoggedInTo(['send-email']);

const routes: Routes = [
  { path: '', redirectTo: 'admin', pathMatch: 'full' },
  {
    path: 'admin', ...canActivate(redirectUnauthorizedToLogin),
    loadChildren: () => import('./pages/admin-view/admin-view.module').then( m => m.AdminViewModule)
  },
  { path: 'survey', loadChildren: () => import('./pages/survey-view/survey-view.module').then( m => m.SurveyViewModule) },
  { path: 'sign-in', component: SignInComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: '**', redirectTo: 'admin', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
