export class User{

  public static modelFromJson(obj:any){
    return new User(
      obj['userId'],
      obj['email'],
      obj['displayName'],
      obj['disabled'],
    );
  }

  constructor(
    public userId: string ="",
    public email: string = "",
    public displayName: string = "",
    public disabled: boolean
  ){

  }
}
