<div class="splash" [ngClass]="{'active': !endRendering}">
  <div class="splash-icon d-flex align-items-center justify-content-center">
    <img src="../../../../assets/images/logos/EnsignLogo-WhiteOutline.png" alt="Ensign Argentina SA" style="max-width: 350px;margin-top: -150px;">
  </div>
</div>

<main class="main h-100 w-100">
  <div class="container h-100">
    <div class="row h-100">
      <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
        <div class="d-table-cell align-middle">

          <div class="text-center mt-4">
            <h1 class="h2">Bienvenido</h1>
            <p class="lead">
              Inicie sesión en su cuenta
            </p>
            <h2 class="text-primary fw-bolder text-center">Net Promoter Score</h2>
          </div>

          <div class="card">
            <div class="card-body pt-1">
              <div class="m-sm-4">
                <form [formGroup]="signInForm" (submit)="onSubmit()">
                  <div *ngIf="showErrorMessage" class="alert alert-danger alert-dismissible" role="alert">
                    <div class="alert-message">
                      Nombre de Usuario y/o contraseña incorrecta
                    </div>
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>
                  <div class="mb-3">
                    <label>Email</label>
                    <input class="form-control form-control-lg" type="email"  name="email" formControlName="email" placeholder="Introduzca su email" />
                  </div>
                  <div class="mb-3">
                    <label>Password</label>
                    <input class="form-control form-control-lg" type="password" name="password" formControlName="password" placeholder="Introduzca su password" />
                    <small>
                      <a href="javascript:void(0);" [routerLink]="['/reset-password']">Olvidó su password?</a>
                    </small>
                  </div>
                  <div>
                    <div class="form-check align-items-center">
                      <input id="customControlInline" type="checkbox" class="form-check-input" value="remember-me" name="remember-me"
                        checked>
                      <label class="form-check-label text-small" for="customControlInline">Recordarme la próxima vez</label>
                    </div>
                  </div>
                  <div class="text-center mt-3">
                    <button type="sumbit" class="btn btn-lg btn-primary" [disabled]="signInForm.invalid">
                      <span *ngIf="showButtonSpinner" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Iniciar sesión
                      </button>
                    <!-- <button type="submit" class="btn btn-lg btn-primary">Sign in</button> -->
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</main>

<svg width="0" height="0" style="position:absolute;">
  <defs>
    <symbol viewBox="0 0 512 512" id="ion-ios-pulse-strong">
      <path
        d="M448 273.001c-21.27 0-39.296 13.999-45.596 32.999h-38.857l-28.361-85.417a15.999 15.999 0 0 0-15.183-10.956c-.112 0-.224 0-.335.004a15.997 15.997 0 0 0-15.049 11.588l-44.484 155.262-52.353-314.108C206.535 54.893 200.333 48 192 48s-13.693 5.776-15.525 13.135L115.496 306H16v31.999h112c7.348 0 13.75-5.003 15.525-12.134l45.368-182.177 51.324 307.94c1.229 7.377 7.397 11.92 14.864 12.344.308.018.614.028.919.028 7.097 0 13.406-3.701 15.381-10.594l49.744-173.617 15.689 47.252A16.001 16.001 0 0 0 352 337.999h51.108C409.973 355.999 427.477 369 448 369c26.511 0 48-22.492 48-49 0-26.509-21.489-46.999-48-46.999z">
      </path>
    </symbol>
  </defs>
</svg>
