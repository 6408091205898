// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'netpromoterscore-81aca',
    appId: '1:395963171605:web:db25e1d18eede3bf49df1c',
    storageBucket: 'netpromoterscore-81aca.appspot.com',
    apiKey: 'AIzaSyCLTV6XauMbZ_s8N7_Wg81flqTLJmO42sk',
    authDomain: 'netpromoterscore-81aca.firebaseapp.com',
    messagingSenderId: '395963171605',
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
