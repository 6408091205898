import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IdentityService } from 'src/app/services/identity.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public endRendering:boolean=false;
  public showButtonSpinner = false;
  public showErrorMessage: boolean = false;
  public showSuccessMessage: boolean = false;
  public alertText: string = "";

  resetPasswordForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]]
  });

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private idendityService: IdentityService
  ) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.endRendering = true;
    },2600);
  }

  onSubmit(){
    this.showButtonSpinner = true;
    this.idendityService.resetPassword(this.resetPasswordForm.get('email')?.value)
    .then(()=>{
      this.showSuccessMessage = true;
      setTimeout(()=>{
        this.router.navigate(['/sign-in']);
      },4000);

    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      if(errorCode==='auth/user-not-found'){
        this.alertText = "Usuario no encontrado"
      }
      else{
        this.alertText = "Error desconocido, contacte al administrador del sistema";
      }

      this.showErrorMessage = true;
      setTimeout(()=>{
        this.showErrorMessage = false;
      }, 4000);

    })
    .finally(()=>{
      this.showButtonSpinner = false;
    });
  };

}
